import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import useLoadTracker from '@cb/product-react/Hooks/UseLoadTracker';
import ErrorUtils from '@cb/product-react/Utils/ErrorUtils';
import { ModalAction } from '@cb/solaris-react/Components/Interactive/Modal/Modal';
import ModalContent from '@cb/solaris-react/Components/Interactive/Modal/ModalContent';
import { PropsWithClassName } from '@cb/solaris-react/Utility/PropUtils';
import { ModalWidths } from '@cb/solaris-react/Components/Interactive/Modal/Modal';
import ModalManager from '@cb/solaris-react/Utility/ModalManager';
import Select, { SelectOption } from '@cb/solaris-react/Components/Input/Select/Select';
import Input from '@cb/solaris-react/Components/Input/Input';
import Radio from '@cb/solaris-react/Components/Input/Radio';

export type MoveToNamespaceModalModalProps = PropsWithClassName<{
	current: string;
	items: string[];
	onMoveRequested: (path: string) => Promise<void>;
	title: string;
}>;

export default function MoveToNamespaceModalModal(props: MoveToNamespaceModalModalProps) {
	const { current, items, onMoveRequested, title } = props;
	const [selected, setSelected] = useState<string>(current);
	const [newNamespaceValue, setNewNamespaceValue] = useState('');
	const [radioValue, setRadioValue] = useState<'existing' | 'new'>('existing');

	const { addLoader, removeLoader, isLoading } = useLoadTracker();

	const handleMoveRequested = useCallback(async () => {
		const loader = addLoader();
		try {
			if (radioValue === 'existing') {
				await onMoveRequested(selected);
			} else if (radioValue === 'new') {
				const newPath =
					selected.replace(/\/$/, '') +
					'/' +
					newNamespaceValue.trim().replace(/^\//, '').replace(/\/$/, '').replaceAll(/\/+/g, '/');
				await onMoveRequested(newPath);
			}
			removeLoader(loader);
			ModalManager.hideModal();
		} catch (err) {
			ErrorUtils.handleError(err, 'Failed to move', () => {
				removeLoader(loader);
			});
		}
	}, [addLoader, newNamespaceValue, onMoveRequested, radioValue, removeLoader, selected]);

	const noChangeInFolder = useMemo(
		() => radioValue === 'existing' && selected === current,
		[radioValue, selected, current],
	);

	const isValid = useMemo(
		() => radioValue === 'existing' || (radioValue === 'new' && selected && newNamespaceValue.length > 0),
		[newNamespaceValue.length, radioValue, selected],
	);

	const actions: ModalAction[] = useMemo(
		() => [
			{
				callback: () => {
					ModalManager.hideModal();
				},
				variant: 'ghost',
				text: 'Cancel',
			},
			{
				callback: handleMoveRequested,
				scheme: 'info',
				text: 'Move',
				disabled: isLoading || noChangeInFolder || !isValid,
			},
		],
		[handleMoveRequested, isLoading, isValid, noChangeInFolder],
	);

	const options = items.map<SelectOption<string>>((item) => ({
		value: item,
		display: item,
		key: item,
	}));

	const content = (
		<Content>
			<Radio
				className="radio existing"
				value="existing"
				label="Select existing folder"
				checked={radioValue === 'existing'}
				update={() => setRadioValue('existing')}
			/>
			{radioValue === 'existing' && (
				<Select
					options={options}
					label="Select existing"
					currentOption={options.find((x) => x.value === selected)}
					update={(option) => setSelected(option.value)}
					searchable
				/>
			)}
			<Radio
				className="radio new"
				value="new"
				label="Or create new"
				checked={radioValue === 'new'}
				update={() => setRadioValue('new')}
			/>
			{radioValue === 'new' && (
				<>
					<Select
						options={options}
						label="Select parent folder"
						currentOption={options.find((x) => x.value === selected)}
						update={(option) => setSelected(option.value)}
						searchable
					/>
					<Input label="Folder name" value={newNamespaceValue} onChange={setNewNamespaceValue} />
				</>
			)}
		</Content>
	);

	return <ModalContent title={title} actions={actions} body={content} />;
}

const Content = styled.div`
	display: flex;
	flex-direction: column;
	width: min(400px, 90vw);
	margin-top: 1.5rem;
	padding: 2px;

	.radio.new {
		margin-top: 2rem;
	}
`;

export function showMoveToNamespaceModalModal(props: MoveToNamespaceModalModalProps) {
	ModalManager.createAndShowModal({
		maxWidth: ModalWidths.MEDIUM,
		allowWrapperClickToClose: false,
		content: <MoveToNamespaceModalModal {...props} />,
	});
}
