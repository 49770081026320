import React, { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { EmptyStateContainer, GridCardListType, GridListItem } from '@cb/product-react/Components/General/GridCardList';
import {
	BasicRepositoryCredentials,
	useRepositoryCredentialsService,
} from '@cb/product-react/Services/RepositoryCredentialsService';
import Paginator from '../../../../../Components/Layout/Paginator';
import StepperModalContent, {
	StepperModalContentItem,
} from '@cb/solaris-react/Components/Interactive/Modal/StepperModalContent';
import { AuthMethodDetailsMap, ProviderDetailsMap } from '../../Credentials/Credentials';
import { ProviderDescription, ProviderIcon, StyledCardList } from '../../Credentials/Modals/NewCredentialsModal';
import { Link } from 'react-router-dom';
import { InputBackground } from '@cb/product-react/Components/Layout/CommonPageComponents';
import { BasicRepositoryValidation, RepositoryForCreate } from '@cb/product-react/Services/RepositoryService';
import { ModalSearchBar, SelectableGridCardList } from '../../../../../Components/Modals/Common';
import Icon from '@cb/solaris-react/Components/Content/Icon';
import Input from '@cb/solaris-react/Components/Input/Input';
import useInheritedTheme from '@cb/solaris-react/Hooks/UseInheritedTheme';
import useValidation from '@cb/solaris-react/Hooks/UseValidation';
import ModalManager from '@cb/solaris-react/Utility/ModalManager';
import useDebounce from '@cb/product-react/Hooks/UseDebounce';
import Select, { SelectOption } from '@cb/solaris-react/Components/Input/Select/Select';
import { RepositoryProvider } from '@cb/product-react/Graphql/__generated__/graphql';
import NewContainingFolderLabel from '@cb/product-react/Components/Crud/NewContainingFolderLabel';

export type RepositoryModel = {
	provider?: RepositoryProvider;
	repositoryCredentialsId?: string;
	isCredentialsRequired?: boolean;
	name?: string;
	path?: string;
	uri?: string;
	repositoryCredentials?: BasicRepositoryCredentials;
};

type RepositoryModelProps = {
	model: RepositoryModel;
	setModel: Dispatch<SetStateAction<RepositoryModel>>;
};

interface NewRepositoryModalProps {
	organisationId: string;
	create: (entity: RepositoryForCreate) => void;
	namespaces: string[];
}

export default function NewRepositoryModal(props: NewRepositoryModalProps) {
	const { organisationId, create, namespaces } = props;

	const [repositoryModel, setRepositoryModel] = useState<RepositoryModel>({});

	const createRepository = useCallback(() => {
		if (!repositoryModel.name || !repositoryModel.uri || !repositoryModel.provider) {
			return;
		}

		const repositoryToCreate: RepositoryForCreate = {
			name: repositoryModel.name,
			path: repositoryModel.path ?? '/',
			uri: repositoryModel.uri,
			provider: repositoryModel.provider,
			repositoryCredentialsId: repositoryModel.repositoryCredentialsId ?? null,
		};

		create(repositoryToCreate);
	}, [create, repositoryModel]);

	const items: Array<StepperModalContentItem> = useMemo(
		() => [
			{
				canGoBack: () => false,
				canGoNext: () => repositoryModel.provider !== undefined,
				component: () => <SelectProvider model={repositoryModel} setModel={setRepositoryModel} />,
			},
			{
				canGoBack: () => true,
				canGoNext: () =>
					repositoryModel.isCredentialsRequired
						? repositoryModel.repositoryCredentialsId !== undefined
						: true,
				component: () => (
					<SelectCredentials
						organisationId={organisationId}
						model={repositoryModel}
						setModel={setRepositoryModel}
					/>
				),
			},
			{
				canGoBack: () => true,
				canGoNext: () => (repositoryModel.name ? true : false) && (repositoryModel.uri ? true : false),
				component: () => (
					<DetailsForm model={repositoryModel} setModel={setRepositoryModel} namespaces={namespaces} />
				),
			},
			{
				canGoBack: () => true,
				canGoNext: () => true,
				callback: createRepository,
				component: () => <Summary model={repositoryModel} setModel={setRepositoryModel} />,
			},
		],
		[createRepository, namespaces, organisationId, repositoryModel],
	);
	return <StepperModalContent title="New Repository" items={items} />;
}

function Summary(props: RepositoryModelProps) {
	const { model } = props;

	// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
	const provider = model.provider!;
	const authMethod = model.repositoryCredentials?.authMethod;

	return (
		<InputBackground>
			<h3>Summary</h3>
			<p>
				<strong>Name:</strong> {model.name}
			</p>
			<p>
				<strong>URI:</strong> {model.uri}
			</p>
			<p>
				<strong>Provider:</strong> {ProviderDetailsMap[provider].name}
			</p>
			<p>
				<strong>Credentials:</strong>{' '}
				{model.repositoryCredentials && authMethod
					? `${model.repositoryCredentials.name} - ${AuthMethodDetailsMap[authMethod].name}`
					: 'None (Public repository)'}
			</p>
		</InputBackground>
	);
}

type DetailsFormProps = RepositoryModelProps & {
	namespaces: string[];
};

function DetailsForm(props: DetailsFormProps) {
	const { model, setModel, namespaces } = props;

	const [name, setName] = useState<string>(model.name ?? '');
	const [path, setPath] = useState<string>(model.path ?? '/');
	const [uri, setUri] = useState<string>(model.uri ?? '');

	const { applyValidation, validateField, isValid } = useValidation<{ name: string; uri: string }>();

	useEffect(() => {
		setModel((m) => ({ ...m, name: isValid ? name : undefined }));
	}, [name, isValid, setModel]);

	useEffect(() => {
		setModel((m) => ({ ...m, uri: isValid ? uri : undefined }));
	}, [uri, isValid, setModel]);

	useEffect(() => {
		setModel((m) => ({ ...m, path }));
	}, [setModel, path]);

	const selectOptions = namespaces.map<SelectOption<string>>((namespace) => ({
		display: namespace,
		value: namespace,
		key: namespace,
	}));

	return (
		<InputBackground>
			<h3>Repository Details</h3>
			<Select
				options={selectOptions}
				label={<NewContainingFolderLabel entityName="repository" />}
				currentOption={
					selectOptions.find((x) => x.value === path) ?? selectOptions.find((x) => x.value === '/')
				}
				update={(option) => setPath(option.value)}
			/>
			<Input
				label="Name"
				value={name}
				autoFocus
				onChange={(val: string) => {
					setName(val);
					validateField('name', val);
				}}
				validation={applyValidation('name', BasicRepositoryValidation.name)}
			/>
			<Input
				label="URI"
				value={uri}
				onChange={(val: string) => {
					setUri(val);
					validateField('uri', val);
				}}
				validation={applyValidation('uri', BasicRepositoryValidation.uri)}
			/>
		</InputBackground>
	);
}

type SelectCredentialsProps = RepositoryModelProps & {
	organisationId: string;
};

function SelectCredentials(props: SelectCredentialsProps) {
	const { organisationId, model, setModel } = props;

	const theme = useInheritedTheme();
	const credentialsService = useRepositoryCredentialsService();

	const [isLoading, setIsLoading] = useState(false);
	const [isCredentialsRequired, setIsCredentialsRequired] = useState<boolean | undefined>(undefined);
	const [credentials, setCredentials] = useState<BasicRepositoryCredentials[]>();

	const [credentialsSearchTerm, setCredentialsSearchTerm] = useState<string>('');

	// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
	const provider = model.provider!;

	const credentialsSearchTermDebounced = useDebounce(credentialsSearchTerm, 500);

	const fetchCredentials = useCallback(() => {
		return credentialsService.fetchPaginatedCredentialsByProvider(
			organisationId,
			model.provider ?? RepositoryProvider.GENERIC,
			credentialsSearchTermDebounced,
		);
	}, [credentialsService, model.provider, organisationId, credentialsSearchTermDebounced]);

	const credentialItems: GridListItem<BasicRepositoryCredentials>[] =
		credentials?.map((credential) => {
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			const authMethod = credential.authMethod!;
			return {
				key: credential.id,
				model: credential,
				className: model.repositoryCredentialsId == credential.id ? 'selected' : '',
				fields: [
					{
						children: <Icon name={AuthMethodDetailsMap[authMethod].icon} />,
						gridWidth: 'auto',
					},
					{
						children: AuthMethodDetailsMap[authMethod].name,
						gridWidth: '1fr',
					},
					{
						children: credential.name,
						gridWidth: '1fr',
					},
				],
				primaryActions: [
					{
						children: provider == RepositoryProvider.GENERIC ? 'Select' : 'Coming soon',
						onClick: () => {
							setModel((m) => ({
								...m,
								repositoryCredentials: credential,
								repositoryCredentialsId: credential.id,
							}));
						},
					},
				],
			};
		}) ?? [];

	const items: GridListItem<boolean>[] = [
		{
			key: 'yes',
			model: true,
			className: isCredentialsRequired === true ? 'selected' : '',
			fields: [
				{
					children: <ProviderIcon name="lock" />,
				},
				{
					children: <p>Private repository</p>,
				},
				{
					children: (
						<ProviderDescription>Credentials are required to connect to the repository</ProviderDescription>
					),
				},
			],
			primaryActions: [
				{
					children: 'Select',
					onClick: () => {
						setIsCredentialsRequired(true);
						setModel((m) => ({ ...m, isCredentialsRequired: true }));
					},
				},
			],
		},
		{
			key: 'no',
			model: false,
			className: isCredentialsRequired === false ? 'selected' : '',
			fields: [
				{
					children: <ProviderIcon name="lock-open" />,
				},
				{
					children: <p>Public repository</p>,
				},
				{
					children: (
						<ProviderDescription>
							No credentials are required to connect to the repository
						</ProviderDescription>
					),
				},
			],
			primaryActions: [
				{
					children: 'Select',
					onClick: () => {
						setIsCredentialsRequired(false);
						setModel((m) => ({ ...m, isCredentialsRequired: false }));
					},
				},
			],
		},
	];

	return (
		<>
			<StyledCardList
				type={GridCardListType.Cards}
				showTypeToggle={false}
				items={items}
				backgroundColor={theme.schemes.tertiary.s50}
				highlightOnHover={false}
				maxHeight="60vh"
			/>
			{isCredentialsRequired && (
				<>
					<p>Please select the credentials you wish to use for this repository.</p>
					<ModalSearchBar
						placeholder="Search credentials..."
						onSearchChange={(newVal) => setCredentialsSearchTerm(newVal)}
						onSearchCleared={() => setCredentialsSearchTerm('')}
					/>
					<SelectableGridCardList
						type={GridCardListType.Grid}
						showTypeToggle={false}
						items={credentialItems}
						backgroundColor={theme.schemes.tertiary.s50}
						isLoading={isLoading}
						emptyState={
							<EmptyStateContainer>
								<p>No credentials exist for {ProviderDetailsMap[provider].name}.</p>
								<p>
									Navigate to the{' '}
									<Link
										to={`/${organisationId}/repositories/credentials`}
										onClick={() => ModalManager.hideModal()}
									>
										Credentials page
									</Link>{' '}
									to create some first.
								</p>
							</EmptyStateContainer>
						}
						highlightOnHover={false}
						maxHeight="30vh"
					/>
					<Paginator<BasicRepositoryCredentials>
						paginatedFunction={fetchCredentials}
						onItemsChanged={setCredentials}
						onIsLoadingChanged={setIsLoading}
					/>
				</>
			)}
		</>
	);
}

function SelectProvider(props: RepositoryModelProps) {
	const { model, setModel } = props;
	const theme = useInheritedTheme();

	const items: GridListItem<RepositoryProvider>[] = (Object.keys(ProviderDetailsMap) as RepositoryProvider[]).map(
		(provider) => ({
			key: provider,
			model: provider,
			className: `provider-${provider.toLowerCase()} ${model.provider == provider ? 'selected' : ''}`,
			fields: [
				{
					children: <ProviderIcon name={ProviderDetailsMap[provider].icon} />,
				},
				{
					children: <p>{ProviderDetailsMap[provider].name}</p>,
				},
				{
					children: <ProviderDescription>{ProviderDetailsMap[provider].description}</ProviderDescription>,
				},
			],
			primaryActions: [
				{
					children: provider == RepositoryProvider.GENERIC ? 'Select' : 'Coming soon',
					disabled: provider != RepositoryProvider.GENERIC,
					onClick: () => {
						setModel((m) => ({ ...m, provider }));
					},
				},
			],
		}),
	);

	return (
		<StyledCardList
			type={GridCardListType.Cards}
			showTypeToggle={false}
			items={items}
			backgroundColor={theme.schemes.tertiary.s50}
			highlightOnHover={false}
			maxHeight="60vh"
		/>
	);
}
