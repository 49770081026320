import React, { useRef, useState } from 'react';
import { PropsWithClassName } from '@cb/solaris-react/Utility/PropUtils';
import { useRepositoryService } from '@cb/product-react/Services/RepositoryService';
import ModalContent from '@cb/solaris-react/Components/Interactive/Modal/ModalContent';
import useLoadTracker from '@cb/product-react/Hooks/UseLoadTracker';
import { ModalAction } from '@cb/solaris-react/Components/Interactive/Modal/Modal';
import modalManager from '@cb/solaris-react/Utility/ModalManager';
import ErrorUtils from '@cb/product-react/Utils/ErrorUtils';
import { Result } from '@cb/common-ts/Result';
import styled, { css } from 'styled-components';
import Icon from '@cb/solaris-react/Components/Content/Icon';
import LoadingSpinner from '@cb/solaris-react/Components/Loading/LoadingSpinner';
import { AbortType, FetchClientError } from '@cb/common-ts/FetchClient';

export type TestRepositoryModalProps = PropsWithClassName<{ repositoryId: string }>;

export default function TestRepositoryModal(props: TestRepositoryModalProps) {
	const { repositoryId } = props;

	const { isLoading, addLoader, removeLoader } = useLoadTracker();
	const repositoryService = useRepositoryService();

	const [testResult, setTestResult] = useState<Result<never> | null>(null);

	const abortTest = useRef(() => {});

	const testRepository = async () => {
		const loader = addLoader();
		try {
			setTestResult(null);
			const request = repositoryService.testRepository(repositoryId);
			abortTest.current = request.abort;
			const response = await request.response;
			setTestResult(response);
		} catch (err) {
			setTestResult(null);
			// Ignore any abort errors
			if (err instanceof FetchClientError) {
				const fetchError = err as FetchClientError<never>;
				if (fetchError.abortType === AbortType.UNMOUNT || fetchError.abortType === AbortType.EXPLICIT) {
					return;
				}
			}
			ErrorUtils.handleError(err, 'Failed to test repository');
		} finally {
			removeLoader(loader);
		}
	};

	const actions: ModalAction[] = [
		{
			text: isLoading ? 'Cancel Test' : 'Close',
			variant: 'ghost',
			callback: () => {
				abortTest.current();
				modalManager.hideModal();
			},
		},
		{
			text: 'Begin Test',
			scheme: 'info',
			disabled: isLoading,
			callback: testRepository,
		},
	];

	const body = (
		<StyledTestRepositoryModal>
			<p>
				This test will attempt a few different operations on your repository to ensure everything is working
				properly.
			</p>
			<p>The test includes:</p>
			<ul>
				<li>Checking if the repository is accessible</li>
				<li>Checking if the repository can be cloned</li>
				<li>Checking if the repository can be committed to</li>
				<li>Checking if the repository can be pushed to</li>
				<li>Checking if a branch can be created, pushed, and deleted.</li>
			</ul>
			{isLoading && <LoadingSpinner text="Testing in progress..." />}
			{testResult?.isSuccess && (
				<>
					<p className="test-result success">
						<Icon name="check" color="var(--success-500)" />
						<span>Test successful!</span>
						<Icon name="check" color="var(--success-500)" />
					</p>
					<p>Congratulations, your repository has passed all tests!</p>
				</>
			)}
			{testResult?.isSuccess === false && (
				<>
					<p className="test-result error">
						<Icon name="error" color="var(--error-500)" />
						<span>Test failed</span>
						<Icon name="error" color="var(--error-500)" />
					</p>
					<p>{testResult.message}</p>
				</>
			)}
		</StyledTestRepositoryModal>
	);

	return <ModalContent title="Test repository" actions={actions} body={body} />;
}

const StyledTestRepositoryModal = styled.div`
	${({ theme }) => css`
		background: var(--bg-primary);
		padding: 1rem;
		margin-top: 1rem;

		p {
			margin-top: 0;
		}
		li {
			margin-bottom: 0.5rem;
		}

		.test-result {
			margin-top: 3rem;
			font-size: ${theme.typography.lg};
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 1rem;
			> span {
				font-weight: bold;
			}
		}
	`};
`;
