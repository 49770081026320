import React, { Suspense } from 'react';
import AiLabPage from '../../../Components/Layout/AiLabPage';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { VISUAL_LIBRARY_ID_PARAM } from '@cb/product-react/Hooks/UseParam';
import { ProjectRoutes } from '../../../Utils/NavigationUtils';
import InlineSpinner from '@cb/solaris-react/Components/Loading/InlineSpinner';
const VisualLibrary = React.lazy(() => import('./VisualLibrary/VisualLibrary'));
const VisualLibraries = React.lazy(() => import('./VisualLibraries'));

export default function VisualPage() {
	const { path } = useRouteMatch();
	return (
		<AiLabPage pageTitle="Visual libraries" projectRoute={ProjectRoutes.Visual}>
			<Suspense fallback={<InlineSpinner overlay />}>
				<Switch>
					<Route path={`${path}/:${VISUAL_LIBRARY_ID_PARAM}`}>
						<VisualLibrary />
					</Route>
					<Route path={path}>
						<VisualLibraries />
					</Route>
				</Switch>
			</Suspense>
		</AiLabPage>
	);
}
