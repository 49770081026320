import {
	BasicPipelineRunnerValidation,
	PipelineRunnerForCreate,
} from '@cb/product-react/Services/PipelineRunnerService';
import Input from '@cb/solaris-react/Components/Input/Input';
import { ModalAction } from '@cb/solaris-react/Components/Interactive/Modal/Modal';
import ModalContent from '@cb/solaris-react/Components/Interactive/Modal/ModalContent';
import useValidation from '@cb/solaris-react/Hooks/UseValidation';
import ModalManager from '@cb/solaris-react/Utility/ModalManager';
import RadioGroup, { RadioGroupProps } from '@cb/solaris-react/Components/Input/RadioGroup';
import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';

interface NewPipelineRunnerModalProps {
	onClose?: () => void;
	create: (entity: PipelineRunnerForCreate) => void;
}

export default function NewPipelineRunnerModal(props: NewPipelineRunnerModalProps) {
	const { onClose, create: create } = props;
	const [name, setName] = useState('');
	const [isShared, setIsShared] = useState<boolean>(true);

	const { applyValidation, validateField, validateAll } = useValidation<{ name: string }>();

	const createPipelineRunner = useCallback(() => {
		if (
			!validateAll({
				name,
			})
		) {
			return;
		}
		create({
			name,
			isShared,
		});
	}, [create, name, validateAll, isShared]);

	const actions: ModalAction[] = useMemo(
		() => [
			{
				callback: () => {
					onClose?.();
					ModalManager.hideModal();
				},
				variant: 'ghost',
				text: 'Close',
			},
			{
				callback: createPipelineRunner,
				scheme: 'info',
				text: 'Create',
			},
		],
		[createPipelineRunner, onClose],
	);

	return (
		<ModalContent
			title="New pipeline runner"
			actions={actions}
			body={
				<div style={{ width: 'min(400px, 90vw)' }}>
					<Input
						label="Name"
						value={name}
						autoFocus
						onChange={(val: string) => {
							setName(val);
							validateField('name', val);
						}}
						validation={applyValidation('name', BasicPipelineRunnerValidation.name)}
					/>
					<StyledRadioGroup
						onChange={(val) => setIsShared(val ?? true)}
						groupName={'isShared'}
						value={isShared}
						label="Is this runner just for you, or shared with others in your organisation?"
						options={[
							{
								label: 'Shared - Anyone in my organisation can use this runner',
								value: true,
							},
							{
								label: 'Private - Only I can use this runner',
								value: false,
							},
						]}
					/>
				</div>
			}
		/>
	);
}

const StyledRadioGroup = styled(RadioGroup as (props: RadioGroupProps<boolean>) => JSX.Element)`
	margin-top: ${(props) => props.theme.spacing.md};
`;
