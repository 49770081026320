import React, { useCallback, useMemo } from 'react';
import { PageContent, Title } from '@cb/product-react/Components/Layout/CommonPageComponents';
import {
	useCrudCreate,
	useCrudDelete,
	useCrudEdit,
	useCrudLoadingMessage,
} from '@cb/product-react/Components/Crud/UseCrud';
import CrudPage, { CrudPageProps } from '@cb/product-react/Components/Crud/CrudPage';
import {
	useAccessTokenService,
	AccessTokenForCreate,
	AccessTokenForUpdate,
	BasicAccessToken,
} from '@cb/product-react/Services/AccessTokenService';
import useUser from '@cb/product-react/Hooks/UseUser';
import NewAccessTokenModal from './Modals/NewAccessTokenModal';
import EditAccessTokenModal from './Modals/EditAccessTokenModal';
import styled from 'styled-components';
import TimeUtils, { ParsableDate, TimeFormat } from '@cb/common-ts/TimeUtils';
import RevealAccessTokenModal from './Modals/RevealAccessTokenModal';
import { ModalWidths } from '@cb/solaris-react/Components/Interactive/Modal/Modal';
import ModalManager from '@cb/solaris-react/Utility/ModalManager';
import Tag from '@cb/solaris-react/Components/Content/Tag';

const CRUD_DISPLAY_NAME = 'access token';

export default function AccessTokens() {
	const { user } = useUser();
	const accessTokenService = useAccessTokenService();

	const fetchPaginatedCrudEntities = useCallback(
		(query?: string) => {
			return accessTokenService.fetchPaginatedAccessTokens(user?.id ?? '', query);
		},
		[accessTokenService, user],
	);

	const createCrudEntity = useCallback(
		(entity: AccessTokenForCreate) => {
			return accessTokenService.createAccessToken(entity, user?.id ?? '');
		},
		[accessTokenService, user],
	);

	const editCrudEntity = useCallback(
		(entity: AccessTokenForUpdate): Promise<BasicAccessToken> => {
			return accessTokenService.updateAccessToken({
				...entity,
				userId: user?.id ?? '',
			});
		},
		[accessTokenService, user],
	);

	const deleteCrudEntity = useCallback(
		(entity: BasicAccessToken) => {
			return accessTokenService.delete(entity.id).response;
		},
		[accessTokenService],
	);

	const itemOptions = useMemo<CrudPageProps<BasicAccessToken>['itemOptions']>(
		() => ({
			fields: (accessToken) => [
				{
					children: accessToken.description,
				},
				{
					children: (
						<>
							<Small>Expires (local time)</Small>
							{TimeUtils.getLocalFormattedTime(
								accessToken.expires as ParsableDate,
								`${TimeFormat.DATE_NUMERIC} ${TimeFormat.TIME_12_HOUR}`,
							)}
						</>
					),
					gridWidth: '180px',
				},
				{
					children: TimeUtils.isInFuture(accessToken.expires as ParsableDate) ? null : (
						<Tag text="Expired" scheme="error" />
					),
					gridWidth: '100px',
				},
				{
					children: (
						<div
							title={
								accessToken.lastUsed
									? TimeUtils.getLocalFormattedTime(
											accessToken.lastUsed as ParsableDate,
											`${TimeFormat.DATE_NUMERIC} ${TimeFormat.TIME_12_HOUR} [(local time)]`,
										)
									: 'Never'
							}
						>
							<Small>Last used</Small>
							{accessToken.lastUsed
								? TimeUtils.getLocalFromNow(accessToken.lastUsed as ParsableDate)
								: 'Never'}
						</div>
					),
				},
			],
			primaryActions: (accessToken) => [
				{
					children: 'Reveal token',
					onClick: () => {
						ModalManager.createAndShowModal({
							allowWrapperClickToClose: false,
							content: <RevealAccessTokenModal accessToken={accessToken} />,
						});
					},
				},
			],
		}),
		[],
	);

	const { isCreating, createFunction } = useCrudCreate(
		createCrudEntity,
		(props) => <NewAccessTokenModal {...props} />,
		{
			maxWidth: ModalWidths.LARGE,
		},
	);
	const { isEditing, editFunction } = useCrudEdit(editCrudEntity, (props) => <EditAccessTokenModal {...props} />, {
		maxWidth: ModalWidths.LARGE,
	});
	const { isDeleting, deleteFunction } = useCrudDelete(deleteCrudEntity, (x) => x.description);

	const crudLoadingMessage = useCrudLoadingMessage(CRUD_DISPLAY_NAME, { isCreating, isEditing, isDeleting });

	const loadingMessage = user ? crudLoadingMessage : 'Loading user...';

	return (
		<PageContent blockingSpinnerMessage={loadingMessage}>
			<TitleWithoutMargin>Access Tokens</TitleWithoutMargin>
			<Text>
				Access tokens can be used to connect to the Codebots API, and are required when running pipelines in a
				local environment. Tokens show below are specific to you, and you alone.
			</Text>
			<Warning>
				<strong>Access tokens are essentially passwords - please keep them safe!</strong>
			</Warning>
			{user ? (
				<CrudPage<BasicAccessToken>
					itemOptions={itemOptions}
					crudProps={{
						fetch: fetchPaginatedCrudEntities,
						create: createFunction,
						edit: editFunction,
						remove: deleteFunction,
					}}
					entityDisplayName={CRUD_DISPLAY_NAME}
				/>
			) : null}
		</PageContent>
	);
}

const Small = styled.p`
	margin: 0;
	font-size: ${(props) => props.theme.typography.sm};
	font-weight: bold;
	margin-bottom: ${(props) => props.theme.spacing.xs};
`;

const TitleWithoutMargin = styled(Title)`
	margin-bottom: 0;
`;

const Text = styled.p`
	margin: 0;
`;

const Warning = styled.p`
	margin: 0;
	color: ${(props) => props.theme.schemes.error.s500};
`;
