import React from 'react';
import AiLabPage from '../../../Components/Layout/AiLabPage';
import { Redirect, Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import AccessTokens from './AccessTokens/AccessTokens';
import PipelineRunners from './PipelineRunners/PipelineRunners';
import { HeaderBar } from '@cb/product-react/Components/Layout/HeaderBar';
import { useRequiredOrganisationId } from '@cb/product-react/Hooks/UseOrganisationId';
import { getOrganisationLink } from '@cb/product-react/Utils/NavigationUtils';
import { PageWrapper } from '@cb/product-react/Components/Layout/CommonPageComponents';
import { ProjectRoutes } from '../../../Utils/NavigationUtils';
import { ContextMenuItemProps } from '@cb/solaris-react/Components/Interactive/ContextMenu/ContextMenu';
import DiagramTabDropdown from '../../Diagram/DiagramTabDropdown';

enum SettingsTab {
	PipelineRunners = '/settings/pipeline-runners',
	AccessTokens = '/settings/access-tokens',
}

export default function SettingsPage() {
	const { path, url } = useRouteMatch();
	const history = useHistory();
	const organisationId = useRequiredOrganisationId();

	const getTab = (newTab: SettingsTab) => {
		return getOrganisationLink(organisationId, newTab);
	};

	const items: (ContextMenuItemProps & {
		tab: SettingsTab;
	})[] = [
		{
			text: 'Pipeline Runners',
			to: getTab(SettingsTab.PipelineRunners),
			tab: SettingsTab.PipelineRunners,
		},
		{
			text: 'Access Tokens',
			to: getTab(SettingsTab.AccessTokens),
			tab: SettingsTab.AccessTokens,
		},
	];

	const currentTab = items.find((item) => history.location.pathname.endsWith(item.tab));

	return (
		<AiLabPage pageTitle="Settings" projectRoute={ProjectRoutes.Settings}>
			<PageWrapper>
				<HeaderBar>
					<DiagramTabDropdown items={items.filter((x) => x !== currentTab)}>
						{currentTab?.text ?? 'Unknown'}
					</DiagramTabDropdown>
				</HeaderBar>
				<Switch>
					<Route path={`${path}/pipeline-runners`}>
						<PipelineRunners />
					</Route>
					<Route path={`${path}/access-tokens`}>
						<AccessTokens />
					</Route>
					<Route path={path}>
						<Redirect to={`${url}/pipeline-runners`} />
					</Route>
				</Switch>
			</PageWrapper>
		</AiLabPage>
	);
}
