import MaterialIcon from '@cb/solaris-react/Components/Content/MaterialIcon';
import GradientHighlightButton, {
	GradientHighlightButtonProps,
	GradientHighlightButtonSide,
} from '@cb/solaris-react/Components/Interactive/Button/GradientHighlightButton';
import ContextMenu, { ContextMenuItemProps } from '@cb/solaris-react/Components/Interactive/ContextMenu/ContextMenu';
import usePortalPopper from '@cb/solaris-react/Hooks/UsePortalPopper';
import React, { useRef } from 'react';
import styled from 'styled-components';

export interface DiagramTabDropdownProps extends React.ComponentPropsWithoutRef<'button'> {
	items: ContextMenuItemProps[];
	menuClassName?: string;
	onClose?: () => void;
	onOpen?: () => void;
}

const defaultButtonProps = {
	scheme: 'tertiary',
};

export default function DiagramTabDropdown(props: DiagramTabDropdownProps) {
	const { items, menuClassName, onClose, onOpen, children, ...rest } = props;
	const ref = useRef<HTMLButtonElement>(null);

	const handleOpenContextMenu = () => {
		setIsMenuVisible(true);
		onOpen?.();
	};

	const handleClose = () => {
		setIsMenuVisible(false);
		onClose?.();
	};

	const [portal, setIsMenuVisible] = usePortalPopper({
		targetRef: ref,
		children: <ContextMenu items={items} className={menuClassName} close={handleClose} />,

		hideOnOutsideClick: true,
		styles: {
			// allows the dropdown menu to be positioned on top of a modal if the context menu is in a modal
			zIndex: 700,
		},

		updateOnDomMutations: true,
	});

	const finalButtonProps: GradientHighlightButtonProps & React.ComponentProps<typeof GradientHighlightButton> = {
		...defaultButtonProps,
		side: GradientHighlightButtonSide.BOTTOM,
		selected: true,
		onClick: (e: React.MouseEvent<HTMLButtonElement>) => {
			handleOpenContextMenu();
			rest.onClick?.(e);
		},
		ref,
		className: 'diagram-tab-dropdown',
		...rest,
	};
	return (
		<>
			<GradientHighlightButton {...finalButtonProps}>
				<LabelContainer>
					<span>{children}</span>
					<MaterialIcon icon="arrow_drop_down" />
				</LabelContainer>
			</GradientHighlightButton>
			{portal}
		</>
	);
}

const LabelContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;
