import GridCardList from '@cb/product-react/Components/General/GridCardList';
import HeaderSearchBar from '@cb/product-react/Components/General/HeaderSearchBar';
import styled from 'styled-components';

export const SelectableGridCardList = styled(GridCardList)`
	.item {
		border: 2px solid transparent;
		&.selected {
			border: 2px solid ${(props) => props.theme.schemes.primary.s500};
		}
	}

	.items {
		padding-block: 0;
	}
`;

export const CurrentVersionContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: ${(props) => props.theme.spacing.xxs};

	span.current-version {
		font-size: ${(props) => props.theme.typography.sm};
		color: ${(props) => props.theme.schemes.tertiary.s500};
	}
`;

export const ModalSearchBar = styled(HeaderSearchBar)`
	> input {
		background-color: ${(props) => props.theme.schemes.tertiary.s100} !important;
	}
`;
