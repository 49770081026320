import {
	BasicAccessToken,
	BasicAccessTokenValidation,
	AccessTokenForUpdate,
} from '@cb/product-react/Services/AccessTokenService';
import Input from '@cb/solaris-react/Components/Input/Input';
import { ModalAction } from '@cb/solaris-react/Components/Interactive/Modal/Modal';
import ModalContent from '@cb/solaris-react/Components/Interactive/Modal/ModalContent';
import useValidation from '@cb/solaris-react/Hooks/UseValidation';
import ModalManager from '@cb/solaris-react/Utility/ModalManager';
import React, { useCallback, useMemo, useState } from 'react';

interface EditAccessTokenModalProps {
	current: BasicAccessToken;
	update: (entity: AccessTokenForUpdate) => void;
}

export default function EditAccessTokenModal(props: EditAccessTokenModalProps) {
	const { update, current } = props;
	const [description, setDescription] = useState(current.description ?? '');

	const { applyValidation, validateAll, validateField, isValid } = useValidation<{ name: string }>();

	const updateAccessToken = useCallback(() => {
		if (!validateAll({ name: description })) return;
		update({
			id: current.id,
			description,
		});
	}, [current, description, update, validateAll]);

	const actions: ModalAction[] = useMemo(
		() => [
			{
				callback: () => {
					ModalManager.hideModal();
				},
				variant: 'ghost',
				text: 'Close',
			},
			{
				callback: updateAccessToken,
				scheme: 'info',
				text: 'Save',
				disabled: !isValid,
			},
		],
		[updateAccessToken, isValid],
	);

	return (
		<ModalContent
			title="Edit bot"
			actions={actions}
			body={
				<div style={{ width: 'min(400px, 90vw)' }}>
					<Input
						label="Description"
						value={description}
						onChange={(val: string) => {
							setDescription(val);
							validateField('name', val);
						}}
						onEnterPressed={updateAccessToken}
						validation={applyValidation('name', BasicAccessTokenValidation.description)}
					/>
				</div>
			}
		/>
	);
}
