import { BasicBotValidation, BotForCreate } from '@cb/product-react/Services/BotService';
import Input from '@cb/solaris-react/Components/Input/Input';
import Select, { SelectOption } from '@cb/solaris-react/Components/Input/Select/Select';
import { ModalAction } from '@cb/solaris-react/Components/Interactive/Modal/Modal';
import ModalContent from '@cb/solaris-react/Components/Interactive/Modal/ModalContent';
import useValidation from '@cb/solaris-react/Hooks/UseValidation';
import ModalManager from '@cb/solaris-react/Utility/ModalManager';
import React, { useCallback, useMemo, useState } from 'react';

interface NewBotModalProps {
	onClose?: () => void;
	create: (entity: BotForCreate) => void;
	namespaces: string[];
}

export default function NewBotModal(props: NewBotModalProps) {
	const { onClose, create, namespaces } = props;
	const [name, setName] = useState('');
	const [path, setPath] = useState<string>('/');

	const { applyValidation, validateField, validateAll, isValid } = useValidation<{ name: string }>();

	const createBot = useCallback(() => {
		if (!validateAll({ name })) return;
		create({
			name,
			path,
		});
	}, [create, name, path, validateAll]);

	const actions: ModalAction[] = useMemo(
		() => [
			{
				callback: () => {
					onClose?.();
					ModalManager.hideModal();
				},
				variant: 'ghost',
				text: 'Close',
			},
			{
				callback: createBot,
				scheme: 'info',
				text: 'Create',
				disabled: !isValid,
			},
		],
		[createBot, isValid, onClose],
	);

	const selectOptions = namespaces.map<SelectOption<string>>((namespace) => ({
		display: namespace,
		value: namespace,
		key: namespace,
	}));

	return (
		<ModalContent
			title="New bot"
			actions={actions}
			body={
				<div style={{ width: 'min(400px, 90vw)' }}>
					<Select
						options={selectOptions}
						label="Containing folder"
						currentOption={selectOptions.find((x) => x.value === path)}
						update={(option) => setPath(option.value)}
					/>
					<Input
						label="Bot name"
						value={name}
						autoFocus
						onChange={(val: string) => {
							setName(val);
							validateField('name', val);
						}}
						onEnterPressed={createBot}
						validation={applyValidation('name', BasicBotValidation.name)}
					/>
				</div>
			}
		/>
	);
}
