import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import { RepositoryCredentialsEntityType } from '@cb/product-react/Graphql/graphql.types';
import GridCardList, { GridCardListType, GridListItem } from '@cb/product-react/Components/General/GridCardList';
import {
	BasicRepositoryCredentialsValidation,
	RepositoryCredentialsForCreate,
	RepositoryCredentialsSshData,
	useRepositoryCredentialsService,
} from '@cb/product-react/Services/RepositoryCredentialsService';
import debounce from 'lodash-es/debounce';
import { AuthMethodDetailsMap, ProviderDetailsMap } from '../Credentials';
import { CodeValue } from './EditCredentialsModal';
import ErrorUtils from '@cb/product-react/Utils/ErrorUtils';
import StepperModalContent, {
	StepperModalContentItem,
} from '@cb/solaris-react/Components/Interactive/Modal/StepperModalContent';
import { InputBackground } from '@cb/product-react/Components/Layout/CommonPageComponents';
import { RequiredValidation } from '@cb/product-react/Utils/ValidationHelpers';
import Icon from '@cb/solaris-react/Components/Content/Icon';
import Input from '@cb/solaris-react/Components/Input/Input';
import PasswordInput from '@cb/solaris-react/Components/Input/PasswordInput';
import TextArea from '@cb/solaris-react/Components/Input/TextArea';
import Button from '@cb/solaris-react/Components/Interactive/Button/Button';
import LoadingSpinner from '@cb/solaris-react/Components/Loading/LoadingSpinner';
import useInheritedTheme from '@cb/solaris-react/Hooks/UseInheritedTheme';
import useValidation from '@cb/solaris-react/Hooks/UseValidation';
import { PropsWithClassName } from '@cb/solaris-react/Utility/PropUtils';
import Codeblock from '@cb/solaris-react/Components/Content/Codeblock';
import Select, { SelectOption } from '@cb/solaris-react/Components/Input/Select/Select';
import { AuthMethod, RepositoryProvider } from '@cb/product-react/Graphql/__generated__/graphql';
import NewContainingFolderLabel from '@cb/product-react/Components/Crud/NewContainingFolderLabel';

export type SshCredentialData = {
	privateKey?: string;
};

export type BasicCredentialData = {
	username?: string;
	password?: string;
};

export type CredentialData = {
	ssh?: SshCredentialData;
	basic?: BasicCredentialData;
};

export type RepositoryCredentialsDataEntityType = RepositoryCredentialsEntityType & {
	_data?: CredentialData;
};

export type NewCredentialsModalProps = PropsWithClassName<{
	create: (entity: RepositoryCredentialsForCreate) => void;
	namespaces: string[];
}>;

export default function NewCredentialsModal(props: NewCredentialsModalProps) {
	const { create, namespaces } = props;
	const [credentials, setCredentials] = useState<RepositoryCredentialsDataEntityType>({});

	const updateCredentials = useCallback((newCredentials: Partial<RepositoryCredentialsDataEntityType>) => {
		setCredentials((existingCredentials) => ({
			...existingCredentials,
			...newCredentials,
		}));
	}, []);

	const createCredentials = () => {
		if (!credentials.authMethod || !credentials.name || !credentials.provider || !credentials._data) {
			return;
		}

		const credentialsToCreate: RepositoryCredentialsForCreate = {
			authMethod: credentials.authMethod,
			data: JSON.stringify(credentials._data),
			name: credentials.name,
			path: credentials.path ?? '/',
			provider: credentials.provider,
		};

		create(credentialsToCreate);
	};

	const items: Array<StepperModalContentItem> = [
		{
			canGoBack: () => false,
			canGoNext: () => credentials.provider !== undefined && credentials.provider !== null,
			component: () => (
				<SelectProvider {...props} credentials={credentials} onCredentialsChanged={updateCredentials} />
			),
		},
		{
			canGoBack: () => true,
			canGoNext: () => credentials.authMethod !== undefined && credentials.authMethod !== null,
			component: () =>
				credentials.provider === RepositoryProvider.GENERIC && (
					<GenericProvider {...props} credentials={credentials} onCredentialsChanged={updateCredentials} />
				),
		},
		{
			canGoBack: () => true,
			canGoNext: () => {
				if (credentials.authMethod == AuthMethod.SSH) {
					return credentials._data?.ssh?.privateKey !== undefined;
				} else if (credentials.authMethod == AuthMethod.BASIC) {
					return (
						credentials._data?.basic?.username !== undefined &&
						credentials._data?.basic?.password !== undefined
					);
				}

				return false;
			},
			component: () => {
				if (credentials.authMethod == AuthMethod.SSH) {
					return (
						<SshCredentials {...props} credentials={credentials} onCredentialsChanged={updateCredentials} />
					);
				} else if (credentials.authMethod == AuthMethod.BASIC) {
					return (
						<BasicCredentials
							{...props}
							credentials={credentials}
							onCredentialsChanged={updateCredentials}
						/>
					);
				} else {
					// TODO
					return null;
				}
			},
		},
		{
			canGoBack: () => true,
			canGoNext: () => {
				return credentials.name !== undefined && credentials.name?.trim() !== '';
			},
			callback: createCredentials,
			component: () => (
				<Summary
					{...props}
					credentials={credentials}
					onCredentialsChanged={updateCredentials}
					namespaces={namespaces}
				/>
			),
		},
	];

	return <StepperModalContent title="New credentials" items={items} />;
}

type SubPageProps = NewCredentialsModalProps & {
	credentials: RepositoryCredentialsDataEntityType;
	onCredentialsChanged: (credentials: RepositoryCredentialsDataEntityType) => void;
};

type SummaryProps = SubPageProps & {
	namespaces: string[];
};

function Summary(props: SummaryProps) {
	const { credentials, onCredentialsChanged, namespaces } = { ...props };

	const [name, setName] = useState<string>('');
	const [path, setPath] = useState<string>('/');

	const { applyValidation, validateAll } = useValidation<{ name: string }>();

	useEffect(() => {
		if (credentials.path !== path) {
			onCredentialsChanged({
				...credentials,
				path,
			});
		}
	}, [credentials, onCredentialsChanged, path]);

	const handleNameChanged = (name: string) => {
		setName(name);
		if (name.trim() == '') {
			validateAllDebounced.cancel();
			validateAndUpdate(validateAll, credentials, name);
		} else {
			validateAllDebounced(validateAll, credentials, name);
		}
	};

	const validateAndUpdate = useCallback(
		(_validateAll: typeof validateAll, _credentials: typeof credentials, name: string) => {
			if (_validateAll({ name })) {
				onCredentialsChanged({
					..._credentials,
					name,
				});
			} else {
				onCredentialsChanged({
					..._credentials,
					name: undefined,
				});
			}
		},
		[onCredentialsChanged],
	);

	const validateAllDebounced = useMemo(
		() =>
			debounce(
				(_validateAll: typeof validateAll, _credentials: typeof credentials, _name: string) =>
					validateAndUpdate(_validateAll, _credentials, _name),
				600,
			),
		[validateAndUpdate],
	);

	const selectOptions = namespaces.map<SelectOption<string>>((namespace) => ({
		display: namespace,
		value: namespace,
		key: namespace,
	}));

	return (
		<InputBackground>
			<h3>Summary</h3>
			<p>
				<strong>Provider: </strong>
				{credentials.provider && ProviderDetailsMap[credentials.provider].name}
			</p>
			<p>
				<strong>Authentication Method: </strong>
				{credentials.authMethod && AuthMethodDetailsMap[credentials.authMethod].name}
			</p>
			<p>
				Please give your credentials a descriptive name. This will help you to identify them when connecting to
				repositories.
			</p>
			<Select
				options={selectOptions}
				label={<NewContainingFolderLabel entityName="credential" />}
				currentOption={selectOptions.find((x) => x.value === path)}
				update={(option) => setPath(option.value)}
			/>
			<Input
				value={name}
				label="Name"
				autoFocus
				onChange={handleNameChanged}
				validation={applyValidation('name', BasicRepositoryCredentialsValidation.name)}
			/>
		</InputBackground>
	);
}

type SshCredentialsProps = SubPageProps;

function SshCredentials(props: SshCredentialsProps) {
	const { credentials, onCredentialsChanged } = { ...props };
	const [privateKey, setPrivateKey] = useState('');
	const [validatedPrivateKey, setValidatedPrivateKey] = useState<RepositoryCredentialsSshData>();

	const [isValidating, setIsValidating] = useState<boolean>(false);

	const credentialsService = useRepositoryCredentialsService();

	const { applyValidation, validateAll } = useValidation<SshCredentialData>();

	const handleSshKeyChanged = (key: string) => {
		setPrivateKey(key);
		setValidatedPrivateKey(undefined);
		if (key.trim() == '') {
			validateAllDebounced.cancel();
			validateAndUpdate(validateAll, credentials, key, undefined);
		} else {
			validateAllDebounced(validateAll, credentials, key, undefined);
		}
	};

	const validateAndUpdate = useCallback(
		(
			_validateAll: typeof validateAll,
			_credentials: typeof credentials,
			_privateKey: string,
			_validatedPrivateKey?: typeof validatedPrivateKey,
		) => {
			if (_validatedPrivateKey !== undefined && _validateAll({ privateKey: _privateKey })) {
				onCredentialsChanged({
					..._credentials,
					_data: {
						ssh: {
							privateKey: _privateKey,
						},
					},
				});
			} else {
				onCredentialsChanged({
					..._credentials,
					_data: undefined,
				});
			}
		},
		[onCredentialsChanged],
	);

	const validateAllDebounced = useMemo(
		() =>
			debounce(
				(
					_validateAll: typeof validateAll,
					_credentials: typeof credentials,
					_privateKey: string,
					_validatedPrivateKey?: typeof validatedPrivateKey,
				) => validateAndUpdate(_validateAll, _credentials, _privateKey, _validatedPrivateKey),
				600,
			),
		[validateAndUpdate],
	);

	const validatePrivateKey = useCallback(
		async (
			_validateAndUpdate: typeof validateAndUpdate,
			_validateAllDebounced: typeof validateAllDebounced,
			_validateAll: typeof validateAll,
			_credentials: typeof credentials,
			_credentialsService: typeof credentialsService,
			_privateKey: string,
		) => {
			try {
				_validateAllDebounced.cancel();
				setIsValidating(true);
				const request = _credentialsService.validateSshPrivateKey(_privateKey);
				const response = await request.response;
				setValidatedPrivateKey(response);
				_validateAndUpdate(_validateAll, _credentials, _privateKey, response);
				setIsValidating(false);
			} catch (error) {
				ErrorUtils.handleError(error, 'Failed to validated private key', () => {
					setIsValidating(false);
					setValidatedPrivateKey(undefined);
				});
			}
		},
		[],
	);

	const validatePrivateKeyDebounced = useMemo(() => {
		return debounce(
			(
				_validateAndUpdate: typeof validateAndUpdate,
				_validateAllDebounced: typeof validateAllDebounced,
				_validateAll: typeof validateAll,
				_credentials: typeof credentials,
				_credentialsService: typeof credentialsService,
				_privateKey: string,
			) => {
				validatePrivateKey(
					_validateAndUpdate,
					_validateAllDebounced,
					_validateAll,
					_credentials,
					_credentialsService,
					_privateKey,
				).catch((err) => {
					throw err;
				});
			},
			200,
		);
	}, [validatePrivateKey]);

	const handleGenerateClicked = async () => {
		try {
			setIsValidating(true);

			const request = credentialsService.generateSshPrivateKey();
			const keyDetails = await request.response;

			setPrivateKey(keyDetails.privateKey);
			setValidatedPrivateKey(keyDetails);
			setIsValidating(false);

			validateAndUpdate(validateAll, credentials, keyDetails.privateKey, keyDetails);
		} catch (error) {
			ErrorUtils.handleError(error, 'Failed to generate a private key', () => {
				setIsValidating(false);
			});
		}
	};

	return (
		<>
			<InputBackground>
				<h3>Private Key (SSH)</h3>
				<p>
					Please supply an unencrypted (password-less) private key. We support any of the following in PEM,
					PKCS8, or RFC4716 (OpenSSH) format:
				</p>
				<ul>
					<li>ED25519</li>
					<li>ECDSA (256, 384, 521)</li>
					<li>RSA (1024, 2048, 4096, 8192)</li>
				</ul>
				<TextArea
					label="Paste your private key here"
					value={privateKey}
					onChange={handleSshKeyChanged}
					className="code-block"
					validation={applyValidation('privateKey', {
						...RequiredValidation,
						custom: {
							value: (value) => {
								// If there is no value, return true, because we don't want to show an error message
								// The other validation rules will take care of that
								if (value.trim() == '') {
									return true;
								}
								if (validatedPrivateKey === undefined) {
									validatePrivateKeyDebounced(
										validateAndUpdate,
										validateAllDebounced,
										validateAll,
										credentials,
										credentialsService,
										value,
									);
									return false;
								}
								return true;
							},
							message: ' ',
						},
					})}
				/>
				<StyledButton onClick={handleGenerateClicked} variant="solid" scheme="success" disabled={isValidating}>
					Generate a key for me
				</StyledButton>
			</InputBackground>
			{isValidating ? (
				<LoadingSpinner size="sm" text="Validating private key..." />
			) : (
				validatedPrivateKey && (
					<>
						<CodeValue>
							Public Key
							<Codeblock text={validatedPrivateKey.publicKey} />
						</CodeValue>
						<CodeValue>
							MD5 Fingerprint
							<Codeblock text={validatedPrivateKey.fingerprintMd5} />
						</CodeValue>
						<CodeValue>
							SHA256 Fingerprint
							<Codeblock text={validatedPrivateKey.fingerprintSha256} />
						</CodeValue>
					</>
				)
			)}
		</>
	);
}

const StyledButton = styled(Button)`
	display: block;
	margin: ${(props) => props.theme.spacing.sm} 0 0 auto;
`;

type BasicCredentialsProps = SubPageProps;

function BasicCredentials(props: BasicCredentialsProps) {
	const { credentials, onCredentialsChanged } = { ...props };

	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');

	const { applyValidation, validateAll } = useValidation<BasicCredentialData>();

	const handleBasicUsernameChanged = (user: string) => {
		setUsername(user);
		if (user.trim() == '') {
			validateAllDebounced.cancel();
			validateAndUpdate(validateAll, credentials, user, password);
		} else {
			validateAllDebounced(validateAll, credentials, user, password);
		}
	};

	const handleBasicPasswordChanged = (pass: string) => {
		setPassword(pass);
		if (pass.trim() == '') {
			validateAllDebounced.cancel();
			validateAndUpdate(validateAll, credentials, username, pass);
		} else {
			validateAllDebounced(validateAll, credentials, username, pass);
		}
	};

	const validateAndUpdate = useCallback(
		(_validateAll: typeof validateAll, _credentials: typeof credentials, username: string, password: string) => {
			if (_validateAll({ username, password })) {
				onCredentialsChanged({
					..._credentials,
					_data: {
						basic: {
							username,
							password,
						},
					},
				});
			} else {
				onCredentialsChanged({
					..._credentials,
					_data: undefined,
				});
			}
		},
		[onCredentialsChanged],
	);

	const validateAllDebounced = useMemo(
		() =>
			debounce(
				(
					_validateAll: typeof validateAll,
					_credentials: typeof credentials,
					username: string,
					password: string,
				) => validateAndUpdate(_validateAll, _credentials, username, password),
				600,
			),
		[validateAndUpdate],
	);

	return (
		<InputBackground>
			<h3>Username &amp; Password (HTTPS)</h3>
			<Input
				label="Username"
				value={username}
				autoFocus
				onChange={handleBasicUsernameChanged}
				validation={applyValidation('username', BasicRepositoryCredentialsValidation.username)}
			/>
			<PasswordInput
				label="Password"
				value={password}
				onChange={handleBasicPasswordChanged}
				validation={applyValidation('password', BasicRepositoryCredentialsValidation.password)}
			/>
		</InputBackground>
	);
}

function GenericProvider(props: SubPageProps) {
	const theme = useInheritedTheme();

	const items: GridListItem<AuthMethod>[] = [
		{
			key: AuthMethod.SSH,
			model: AuthMethod.SSH,
			className: `method-ssh ${props.credentials.authMethod === AuthMethod.SSH ? 'selected' : ''}`,
			fields: [
				{
					children: <ProviderIcon name={AuthMethodDetailsMap[AuthMethod.SSH].icon} />,
				},
				{
					children: <p>{AuthMethodDetailsMap[AuthMethod.SSH].name}</p>,
				},
				{
					children: (
						<ProviderDescription>{AuthMethodDetailsMap[AuthMethod.SSH].description}</ProviderDescription>
					),
				},
			],
			primaryActions: [
				{
					children: 'Select',
					onClick: () => {
						props.credentials.authMethod = AuthMethod.SSH;
						props.onCredentialsChanged(props.credentials);
					},
				},
			],
		},
		{
			key: AuthMethod.BASIC,
			model: AuthMethod.BASIC,
			className: `method-basic ${props.credentials.authMethod === AuthMethod.BASIC ? 'selected' : ''}`,
			fields: [
				{
					children: <ProviderIcon name={AuthMethodDetailsMap[AuthMethod.BASIC].icon} />,
				},
				{
					children: <p>{AuthMethodDetailsMap[AuthMethod.BASIC].name}</p>,
				},
				{
					children: (
						<ProviderDescription>{AuthMethodDetailsMap[AuthMethod.BASIC].description}</ProviderDescription>
					),
				},
			],
			primaryActions: [
				{
					children: 'Select',
					onClick: () => {
						props.credentials.authMethod = AuthMethod.BASIC;
						props.onCredentialsChanged(props.credentials);
					},
				},
			],
		},
	];

	if (props.credentials.provider) {
		return (
			<StyledCardList
				type={GridCardListType.Cards}
				showTypeToggle={false}
				items={items}
				backgroundColor={theme.schemes.tertiary.s50}
				highlightOnHover={false}
				maxHeight="60vh"
			/>
		);
	} else {
		return null;
	}
}

function SelectProvider(props: SubPageProps) {
	const theme = useInheritedTheme();

	const items: GridListItem<RepositoryProvider>[] = (Object.keys(ProviderDetailsMap) as RepositoryProvider[]).map(
		(provider) => ({
			key: provider,
			model: provider,
			className: `provider-${provider.toLowerCase()} ${props.credentials.provider == provider ? 'selected' : ''}`,
			fields: [
				{
					children: <ProviderIcon name={ProviderDetailsMap[provider].icon} />,
				},
				{
					children: <p>{ProviderDetailsMap[provider].name}</p>,
				},
				{
					children: <ProviderDescription>{ProviderDetailsMap[provider].description}</ProviderDescription>,
				},
			],
			primaryActions: [
				{
					children: provider == RepositoryProvider.GENERIC ? 'Select' : 'Coming soon',
					disabled: provider != RepositoryProvider.GENERIC,
					onClick: () => {
						props.credentials.provider = provider;
						props.onCredentialsChanged(props.credentials);
					},
				},
			],
		}),
	);

	return (
		<StyledCardList
			type={GridCardListType.Cards}
			showTypeToggle={false}
			items={items}
			backgroundColor={theme.schemes.tertiary.s50}
			highlightOnHover={false}
			maxHeight="60vh"
		/>
	);
}

export const ProviderDescription = styled.p`
	text-align: center;
	font-size: ${(props) => props.theme.typography.xs};
`;
export const ProviderIcon = styled(Icon)`
	font-size: ${(props) => props.theme.typography.xl};
`;
export const StyledCardList = styled(GridCardList)`
	${({ theme }) => css`
		margin-top: ${theme.spacing.md};

		&.cards {
			.item {
				padding: ${theme.spacing.sm};
			}
			.primary-actions {
				margin: 0 !important;
			}
		}

		.item {
			border: 2px solid transparent;
			&.selected {
				border: 2px solid ${theme.schemes.tertiary.s800};
				z-index: 1;
			}
		}

		.provider-github,
		.provider-gitlab,
		.provider-bitbucket {
			opacity: 0.6;
			${theme.typography.NoSelect};
		}

		.provider-github {
			&.selected {
				border: 2px solid ${theme.schemes.success.s500};
			}
			${ProviderIcon} {
				color: ${theme.schemes.success.s500};
			}
		}
		.provider-gitlab {
			&.selected {
				border: 2px solid ${theme.schemes.warning.s500};
			}
			${ProviderIcon} {
				color: ${theme.schemes.warning.s500};
			}
		}
		.provider-bitbucket {
			&.selected {
				border: 2px solid ${theme.schemes.info.s500};
			}
			${ProviderIcon} {
				color: ${theme.schemes.info.s500};
			}
		}
		.provider-generic {
			&.selected {
				border: 2px solid ${theme.schemes.secondary.s500};
			}
			${ProviderIcon} {
				color: ${theme.schemes.secondary.s500};
			}
		}
	`}
`;
