import React from 'react';
import AiLabPage from '../../../Components/Layout/AiLabPage';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import Repositories from './Repositories/Repositories';
import Credentials from './Credentials/Credentials';
import { HeaderBar } from '@cb/product-react/Components/Layout/HeaderBar';
import { getOrganisationLink } from '@cb/product-react/Utils/NavigationUtils';
import { useRequiredOrganisationId } from '@cb/product-react/Hooks/UseOrganisationId';
import { PageWrapper } from '@cb/product-react/Components/Layout/CommonPageComponents';
import { ProjectRoutes } from '../../../Utils/NavigationUtils';
import DiagramTabDropdown from '../../Diagram/DiagramTabDropdown';
import { ContextMenuItemProps } from '@cb/solaris-react/Components/Interactive/ContextMenu/ContextMenu';

enum RepositoryTab {
	Repositories = '/repositories',
	Credentials = '/repositories/credentials',
}

export default function RepositoriesPage() {
	const { path } = useRouteMatch();
	const history = useHistory();
	const organisationId = useRequiredOrganisationId();

	const getTab = (newTab: RepositoryTab) => {
		return getOrganisationLink(organisationId, newTab);
	};

	const items: (ContextMenuItemProps & {
		tab: RepositoryTab;
	})[] = [
		{
			text: 'Repositories',
			to: getTab(RepositoryTab.Repositories),
			tab: RepositoryTab.Repositories,
		},
		{
			text: 'Credentials',
			to: getTab(RepositoryTab.Credentials),
			tab: RepositoryTab.Credentials,
		},
	];

	const currentTab = items.find((item) => history.location.pathname.endsWith(item.tab));

	return (
		<AiLabPage pageTitle="Repositories" projectRoute={ProjectRoutes.Repositories}>
			<PageWrapper>
				<HeaderBar>
					<DiagramTabDropdown items={items.filter((x) => x !== currentTab)}>
						{currentTab?.text ?? 'Unknown'}
					</DiagramTabDropdown>
				</HeaderBar>
				<Switch>
					<Route path={`${path}/credentials`}>
						<Credentials />
					</Route>
					<Route path={path}>
						<Repositories />
					</Route>
				</Switch>
			</PageWrapper>
		</AiLabPage>
	);
}
