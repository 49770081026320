import { DiagramNavigationData, useDiagramService } from '@cb/product-react/Services/Diagram/DiagramService';
import LoadingSpinner from '@cb/solaris-react/Components/Loading/LoadingSpinner';
import React, { useEffect, useState } from 'react';
import { Redirect, useLocation, useParams } from 'react-router-dom';
import { getDiagramNavigationRoute } from '../../../Utils/NavigationUtils';

export default function NavigateToDiagram() {
	const { diagramId, centreOnId } = useParams<{ diagramId?: string; centreOnId?: string }>();

	const diagramService = useDiagramService();

	const [diagramNavigationData, setDiagramNavigationData] = useState<DiagramNavigationData>();

	useEffect(() => {
		if (!diagramId) return;
		let cancel = false;
		void diagramService.getDiagramNavigationData(diagramId).response.then((result) => {
			if (cancel) return;
			if (result) {
				setDiagramNavigationData(result);
			}
		});

		return () => {
			cancel = true;
		};
	}, [diagramId, diagramService]);

	const location = useLocation();
	// Parse query parameters
	const searchParams = new URLSearchParams(location.search);
	const version = searchParams.get('version');

	if (!diagramNavigationData) {
		return <LoadingSpinner fullPageOverlay text="Navigating to diagram" />;
	}

	const route = getDiagramNavigationRoute(diagramNavigationData, version, centreOnId);

	return <Redirect to={route} />;
}
