import React from 'react';
import { useRequiredOrganisationId } from '@cb/product-react/Hooks/UseOrganisationId';
import {
	CloseSlideoutPanel,
	SetSlideoutPanel,
	SideMenuLinksComponentGenerator,
	StyledItemsContainer,
} from '@cb/product-react/Components/Layout/SideMenu/SideMenu';
import { LinkSideMenuItem } from '@cb/product-react/Components/Layout/SideMenu/SideMenuItem';
import { getAiLabLink, ProjectRoutes } from '../../../Utils/NavigationUtils';

export interface SideMenuProps {
	setSlideoutPanel: SetSlideoutPanel;
	closeSlideoutPanel: CloseSlideoutPanel;
}

export const projectSideMenuLinksComponentGenerator: SideMenuLinksComponentGenerator = (setSlideout, closeSlideout) => (
	<ProjectSideMenu setSlideoutPanel={setSlideout} closeSlideoutPanel={closeSlideout} />
);

export default function ProjectSideMenu(props: SideMenuProps) {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const { setSlideoutPanel, closeSlideoutPanel } = props;

	const organisationId = useRequiredOrganisationId();

	const aiLabLink = (link: ProjectRoutes) => getAiLabLink(organisationId, link);

	return (
		<>
			<div className="project-switch-icon">
				<img src={'/logo.svg'} alt="Project Logo" />
			</div>
			<StyledItemsContainer>
				<LinkSideMenuItem name="Bots" iconName="bot" link={aiLabLink(ProjectRoutes.Bots)} />
				<LinkSideMenuItem name="Pipelines" iconName="source" link={aiLabLink(ProjectRoutes.Pipelines)} />
				<LinkSideMenuItem name="Metamodels" iconName="workflow" link={aiLabLink(ProjectRoutes.Metamodels)} />
				<LinkSideMenuItem name="Visual" iconName="entity" link={aiLabLink(ProjectRoutes.Visual)} />
				<LinkSideMenuItem name="Models" iconName="flow-connect-type" link={aiLabLink(ProjectRoutes.Models)} />
				<LinkSideMenuItem
					name="Repositories"
					iconName="git-branch"
					link={aiLabLink(ProjectRoutes.Repositories)}
				/>
				<LinkSideMenuItem name="Settings" iconName="gears" link={aiLabLink(ProjectRoutes.Settings)} />
			</StyledItemsContainer>
		</>
	);
}
