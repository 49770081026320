import {
	BasicPipelineRunner,
	BasicPipelineRunnerValidation,
	PipelineRunnerForUpdate,
} from '@cb/product-react/Services/PipelineRunnerService';
import Input from '@cb/solaris-react/Components/Input/Input';
import { ModalAction } from '@cb/solaris-react/Components/Interactive/Modal/Modal';
import ModalContent from '@cb/solaris-react/Components/Interactive/Modal/ModalContent';
import useValidation from '@cb/solaris-react/Hooks/UseValidation';
import ModalManager from '@cb/solaris-react/Utility/ModalManager';
import React, { useCallback, useMemo, useState } from 'react';

interface EditPipelineRunnerModalProps {
	current: BasicPipelineRunner;
	update: (entity: PipelineRunnerForUpdate) => void;
}

export default function EditPipelineRunnerModal(props: EditPipelineRunnerModalProps) {
	const { update, current } = props;
	const [name, setName] = useState(current.name ?? '');

	const { applyValidation, validateAll, validateField, isValid } = useValidation<{ name: string }>();

	const updatePipelineRunner = useCallback(() => {
		if (!validateAll({ name: name })) return;
		update({
			id: current.id,
			name,
		});
	}, [current, name, update, validateAll]);

	const actions: ModalAction[] = useMemo(
		() => [
			{
				callback: () => {
					ModalManager.hideModal();
				},
				variant: 'ghost',
				text: 'Close',
			},
			{
				callback: updatePipelineRunner,
				scheme: 'info',
				text: 'Save',
				disabled: !isValid,
			},
		],
		[updatePipelineRunner, isValid],
	);

	return (
		<ModalContent
			title="Edit bot"
			actions={actions}
			body={
				<div style={{ width: 'min(400px, 90vw)' }}>
					<p>
						<strong>Warning:</strong> Changing the runner name will invalidate the token and a new one will
						need to be generated.
					</p>
					<Input
						label="Name"
						value={name}
						onChange={(val: string) => {
							setName(val);
							validateField('name', val);
						}}
						onEnterPressed={updatePipelineRunner}
						validation={applyValidation('name', BasicPipelineRunnerValidation.name)}
					/>
				</div>
			}
		/>
	);
}
