import React, { useEffect, useState } from 'react';
import {
	BasicRepository,
	BasicRepositoryValidation,
	RepositoryForUpdate,
} from '@cb/product-react/Services/RepositoryService';
import { InputBackground } from '@cb/product-react/Components/Layout/CommonPageComponents';
import { RepositoryModel } from './NewRepositoryModal';
import { ProviderDetailsMap } from '../../Credentials/Credentials';
import {
	BasicRepositoryCredentials,
	useRepositoryCredentialsService,
} from '@cb/product-react/Services/RepositoryCredentialsService';
import ErrorUtils from '@cb/product-react/Utils/ErrorUtils';
import useLoadTracker from '@cb/product-react/Hooks/UseLoadTracker';
import styled from 'styled-components';
import Input from '@cb/solaris-react/Components/Input/Input';
import Select, { SelectOption } from '@cb/solaris-react/Components/Input/Select/Select';
import { ModalAction } from '@cb/solaris-react/Components/Interactive/Modal/Modal';
import ModalContent from '@cb/solaris-react/Components/Interactive/Modal/ModalContent';
import InlineSpinner from '@cb/solaris-react/Components/Loading/InlineSpinner';
import useInheritedTheme from '@cb/solaris-react/Hooks/UseInheritedTheme';
import useValidation from '@cb/solaris-react/Hooks/UseValidation';
import modalManager from '@cb/solaris-react/Utility/ModalManager';
import { RepositoryProvider } from '@cb/product-react/Graphql/__generated__/graphql';

const LOADER_ALL_CREDENTIALS = 'all-credentials';
const LOADER_SELECTED_CREDENTIAL = 'selected-credentials';

interface EditRepositoryModalProps {
	organisationId: string;
	current: BasicRepository;
	update: (entity: RepositoryForUpdate) => void;
}

export default function EditRepositoryModal(props: EditRepositoryModalProps) {
	const { update, current, organisationId } = props;

	const theme = useInheritedTheme();

	const [model, setModel] = useState<RepositoryModel>({
		name: current.name ?? undefined,
		uri: current.uri ?? undefined,
		provider: current.provider ?? undefined,
		isCredentialsRequired: current.repositoryCredentialsId ? true : false,
		repositoryCredentialsId: current.repositoryCredentialsId ?? undefined,
	});

	const [credentials, setCredentials] = useState<BasicRepositoryCredentials[]>([]);

	const { addLoader, removeLoader, isLoading } = useLoadTracker();

	const { applyValidation, validateField, isValid } = useValidation<{ name: string; uri: string }>();

	const repositoryCredentialsService = useRepositoryCredentialsService();

	useEffect(() => {
		const request = repositoryCredentialsService.fetchPaginatedCredentialsByProvider(
			organisationId,
			model.provider ?? RepositoryProvider.GENERIC,
			undefined,
			9999,
		);

		addLoader(LOADER_ALL_CREDENTIALS);

		request.data
			.then((creds) => {
				setCredentials(creds);
				removeLoader(LOADER_ALL_CREDENTIALS);
			})
			.catch((e) => {
				ErrorUtils.handleError(e, 'Failed to load credentials', () => {
					removeLoader(LOADER_ALL_CREDENTIALS);
				});
			});
	}, [addLoader, model.provider, organisationId, removeLoader, repositoryCredentialsService]);

	useEffect(() => {
		if (model.repositoryCredentialsId && !model.repositoryCredentials) {
			const request = repositoryCredentialsService.read(model.repositoryCredentialsId);

			addLoader(LOADER_SELECTED_CREDENTIAL);

			request.response
				.then((credentials) => {
					setModel((m) => ({ ...m, repositoryCredentials: credentials }));
					removeLoader(LOADER_SELECTED_CREDENTIAL);
				})
				.catch((e) => {
					ErrorUtils.handleError(e, 'Failed to load credential', () => {
						removeLoader(LOADER_SELECTED_CREDENTIAL);
					});
				});
		}
	}, [
		addLoader,
		model.repositoryCredentials,
		model.repositoryCredentialsId,
		removeLoader,
		repositoryCredentialsService,
	]);

	const actions: ModalAction[] = [
		{
			text: 'Close',
			variant: 'ghost',
			callback: () => {
				modalManager.hideModal();
			},
		},
		{
			text: 'Save',
			scheme: 'info',
			disabled: !isValid || isLoading,
			callback: () => {
				update({
					...current,
					name: model.name,
					uri: model.uri,
					repositoryCredentialsId: model.repositoryCredentialsId ?? null,
				});
			},
		},
	];

	const credentialSelectOptions: SelectOption<BasicRepositoryCredentials>[] = credentials.map((c) => {
		return {
			key: c.id,
			value: c,
			display: `${c.name} (${c.authMethod})`,
		};
	});

	return (
		<ModalContent
			title="Edit repository"
			actions={actions}
			body={
				<StyledEditRepositoryModal>
					{isLoading ? (
						<InlineSpinner margin={theme.spacing.lg} />
					) : (
						<InputBackground>
							<Input
								label="Name"
								value={model.name}
								onChange={(val: string) => {
									setModel((m) => ({ ...m, name: val }));
									validateField('name', val);
								}}
								validation={applyValidation('name', BasicRepositoryValidation.name)}
							/>
							<Input
								label="Uri"
								value={model.uri}
								onChange={(val: string) => {
									setModel((m) => ({ ...m, uri: val }));
									validateField('uri', val);
								}}
								validation={applyValidation('uri', BasicRepositoryValidation.uri)}
							/>
							<Input
								label="Provider (cannot be changed)"
								value={model.provider ? ProviderDetailsMap[model.provider].name : ''}
								disabled
							/>
							<Select
								label="Credentials"
								options={credentialSelectOptions}
								emptyOption={{
									display: 'None (public repository)',
									key: '',
									value: undefined,
								}}
								currentOption={
									model.repositoryCredentialsId
										? credentialSelectOptions.find((o) => o.key === model.repositoryCredentialsId)
										: undefined
								}
								searchable
								searchDebouceMs={100}
								update={(option) => {
									setModel((m) => ({
										...m,
										repositoryCredentials: option.value,
										repositoryCredentialsId: option.value?.id,
									}));
								}}
							/>
						</InputBackground>
					)}
				</StyledEditRepositoryModal>
			}
		/>
	);
}

const StyledEditRepositoryModal = styled.div`
	width: min(400px, 90vw);
	margin: 0 auto;
`;
