import React, { useEffect, useState } from 'react';
import type { RouteDefinition } from '@cb/product-react/Components/AppSkeleton';
import {
	getAiLabRoute,
	getUnauthenticatedRoute,
	ProjectRoutes,
	RedirectToDefault,
	UnauthenticatedRoutes,
} from '../Utils/NavigationUtils';
import { fontsLoaded } from '@cb/diagram-editor-react/Utils/Helpers';
import { getOrganisationRoute, OrganisationRouteDefinitions } from '@cb/product-react/Utils/NavigationUtils';
import { fetchAndParseConfiguration } from '@cb/product-react/Config/Configuration';
import AppSkeleton from '@cb/product-react/Components/AppSkeleton';
import BotsPage from './Pages/Bots/BotsPage';
import MetamodelsPage from './Pages/Metamodels/MetamodelsPage';
import ModelsPage from './Pages/Models/ModelsPage';
import PipelinesPage from './Pages/Pipelines/PipelinesPage';
import RawPage from './Pages/Raw/RawPage';
import RepositoriesPage from './Pages/Repositories/RepositoriesPage';
import SettingsPage from './Pages/Settings/SettingsPage';
import VisualPage from './Pages/Visual/VisualPage';
import DiagramTestPage from './Pages/DiagramTest/DiagramTestPage';
import NavigateToDiagram from './Pages/Utility/NavigateToDiagram';
import SnowOverlay from './SnowOverlay';
import '@cb/solaris-react/Styles';
import '@cb/diagram-editor-react/Styles';
import '@cb/product-react/Styles';
import '../Styles/index.scss';
import AdminSection from './Pages/Admin/AdminSection';

const authenticatedRoutes: RouteDefinition[] = [
	...OrganisationRouteDefinitions,
	// Organisation chosen but no route provided redirects to the default route
	{
		path: getOrganisationRoute(''),
		exact: true,
		component: <RedirectToDefault />,
	},
	// Main routes
	{
		path: getAiLabRoute(ProjectRoutes.Bots),
		component: <BotsPage />,
	},
	{
		path: getAiLabRoute(ProjectRoutes.Pipelines),
		component: <PipelinesPage />,
	},
	{
		path: getAiLabRoute(ProjectRoutes.Metamodels),
		component: <MetamodelsPage />,
	},
	{
		path: getAiLabRoute(ProjectRoutes.Visual),
		component: <VisualPage />,
	},
	{
		path: getAiLabRoute(ProjectRoutes.Models),
		component: <ModelsPage />,
	},
	{
		path: getAiLabRoute(ProjectRoutes.Repositories),
		component: <RepositoriesPage />,
	},
	{
		path: getAiLabRoute(ProjectRoutes.Settings),
		component: <SettingsPage />,
	},
	{
		path: getAiLabRoute(ProjectRoutes.Raw),
		component: <RawPage />,
	},
	// Utility routes
	{
		path: '/navigate/to/diagram/:diagramId/:centreOnId?',
		component: <NavigateToDiagram />,
	},
];

if (import.meta.env.DEV) {
	authenticatedRoutes.unshift({
		path: '/admin',
		component: <AdminSection />,
	});
}

const unauthenticatedRoutes: RouteDefinition[] = import.meta.env.DEV
	? [
			{
				path: getUnauthenticatedRoute(UnauthenticatedRoutes.DiagramTest),
				component: <DiagramTestPage />,
			},
		]
	: [];

const AILab = () => {
	//debugger;
	const [loadingFonts, setLoadingFonts] = useState<boolean>(true);

	// Font loading
	useEffect(() => {
		fontsLoaded()
			.then(() => {
				auxillaryFontsLoaded()
					.then(() => {
						setLoadingFonts(false);
					})
					.catch((err) => {
						throw err;
					});
			})
			.catch(() => console.error('An error occurred loading fonts'));
	}, []);

	// const today = new Date();
	// const daysTilChristmas = (new Date(today.getFullYear(), 11, 25).getTime() - today.getTime()) / 24 / 60 / 60 / 1000;
	// const chance = 0.01 * (25 - daysTilChristmas);
	const timeForSnow = false; //today.getMonth() === 11 && today.getDate() < 26 && Math.random() < chance;

	return (
		<>
			{timeForSnow && <SnowOverlay />}
			<AppSkeleton
				isProd={import.meta.env.PROD}
				unauthenticatedRoutes={unauthenticatedRoutes}
				authenticatedRoutes={authenticatedRoutes}
				defaultPageTitle="Codebots Platform"
				loadingConditions={[{ condition: loadingFonts, message: 'Loading fonts' }]}
				fetchAndParseConfiguration={fetchAndParseConfiguration}
			/>
		</>
	);
};

export default AILab;

const auxillaryFontsLoaded = async (): Promise<boolean> => {
	if (!document.fonts) {
		console.warn("No support for Fonts API, so can't test for loaded fonts.");
		// Resolve anyway, as we don't want to keep the application waiting
		return false;
	}

	const requiredFonts = ['14px Short Stack'];

	await Promise.all(requiredFonts.map((font) => document.fonts.load(font)));

	return false;
};
